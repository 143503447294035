import * as React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import Dropdown from 'react-bootstrap/Dropdown';
import * as prismic from '@prismicio/client';
import moment from 'moment';
import { Layout } from '../../../components/Layout'
import { components } from '../../../slices'

import {convertPrismicRest} from '../../../utils/utils'
import { Col, Container, Row } from 'react-bootstrap';

const repoName = 'sifi-eats-v2'; // Fill in your repository name.
const accessToken = ''; // If your repo is private, add an access token.
const endpoint = prismic.getEndpoint(repoName); // Format your endpoint.

const ViewMode = {
  Loading: 'loading',
  Week: 'week',
  Day: 'day',
  NotFound: 'not_found'
}
const weekData = [
  {code: 'week', name: 'Woche',},
  {code: 'monday',name: 'Montag',},
  {code: 'tuesday',name: 'Dienstag',},
  {code: 'wednesday',name: 'Mittwoch',},
  {code: 'thursday',name: 'Donnerstag',},
  {code: 'friday',name: 'Freitag',},
]
const weekDays = {}
weekData.forEach(item => {
  weekDays[item.code] = item.name
})

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]
const WeekMenu = (props) => {
  // console.log('WeekMenu props: ', props)
  
  const [state, setState] = React.useState({
    week: 1,
    code: '',
    mode: ViewMode.Loading,
    day: '',
    dates: '',
  })
  const [restaurant, setRestaurant] = React.useState(null)
  const [topMenu, setTopMenu] = React.useState(null)
  const [footer, setFooter] = React.useState(null)

  // Client-side Runtime Data Fetching
  React.useEffect(() => {
    const client = prismic.createClient(endpoint, { fetch, });
    const week = props.weekNumber
    const code = props.restCode

    const day = moment().startOf('year').add(week, 'weeks')
    const startWeek = day.startOf('isoWeek').format('DD.MM')
    const endWeek = day.endOf('isoWeek').format('DD.MM.YYYY')
    const dates = `${startWeek} - ${endWeek}`

    setState(prevState => {
      return {...prevState, week, code, dates, mode: ViewMode.Loading}
    })

    async function loadData() {
      const _topMenu = await loadSingle({client, customType:'top_menu'})
      setTopMenu(_topMenu)
      const _footer = await loadSingle({client, customType:'footer'})
      setFooter(_footer)
      //
      const _rests = await client.getByUID('restaurant','restaurants')
      if (!_rests) return
      const rests = _rests.data.body[0].items
      // console.log('rests: ', rests)
      // fetch menu
      const dataMenu = await loadWeekMenu({client, weekNum: week}) || []
      console.log('dataMenu: ', dataMenu)
      console.log('code: ', code)
      const restRaw = dataMenu.find(item => {
        return item.restaurant === code
      })
      if (!restRaw) {
        setRestaurant(null)
        setState(prevState => { return {...prevState, mode: ViewMode.NotFound} })
        return
      }
      const restConverted = convertPrismicRest({item: restRaw, rests})
      console.log('restaurant: ', restConverted)
      setRestaurant(restConverted)
      const hash = getHash()
      console.log('hash: ', hash)
      let mode = ViewMode.Week, day = ''
      if (hash && days.includes(hash)) {
        mode = ViewMode.Day
        day = hash
      }
      setState(prevState => { return {...prevState, mode, day} })
    }

    loadData()
  }, [])

  const onChangeCombo = (event) => {
    // console.log('event: ', event)
    const {target} = event
    const {name, value} = target
    console.log('combo: %s value: %s', name, value)
    if (value === 'week') {
      window.location.hash=''
      setState(prevState => {
        return {...prevState, mode: ViewMode.Week, day: ''}
      })
    } else {
      window.location.hash=`${value}`
      setState(prevState => {
        return {...prevState, mode: ViewMode.Day, day: value}
      })
    }
  }
  //
  const SingleCell = ({cell}) => {
    const {date,day,code,name, singleCell} = cell

    return (
      <div className="single-cell">
        <div>
          <div dangerouslySetInnerHTML={{__html: singleCell}} />
        </div>
      </div>
    )
  }
  const WeekCells = ({week}) => {
    const {date,day,code,name, weekData} = week

    return (
      <div className="row">
          {days.map((item, index) => (
            <div className="row mb-4" key={index}>
              <div className="col-3">
                <h5 className="fw-600">{weekDays[item]}</h5>
              </div>
              <div className="col-9" >
                <div dangerouslySetInnerHTML={{__html: weekData[item]}} />
              </div>
            </div>
          ))}

      </div>
    )
  }
  const RestaurantWeekMenu = ({rest}) => {
    //
    return (
      <div className="h-100 p-5 border border-2 border-secondary d-flex flex-column justify-content-between">
        {/** HEADER */}
        <div class="d-flex justify-content-center align-items-center mb-5">
					<img src={rest.img} class="img-fluid me-3" style={{height:"64px",objectFit:"contain"}} alt={rest.name + " Logo"}/>
					<div class="h3 fw-600 mb-0" >{rest.name}</div>
				</div>
        <div className="d-flex align-items-center mb-5">
					<div className="w-75" style={{backgroundColor:"#34353b",height:"2px"}}></div>
					<div style={{backgroundColor:"#34353b"}} className="p-2 px-3 rounded-pill text-white d-flex justify-content-center justify-content-lg-between align-items-center w-100">
						<div className="d-none d-lg-block" style={{backgroundColor:"#fff",height:"8px",width:"8px",borderRadius:"4px"}}></div>
						<h5 className="m-0">{state.dates}</h5>
						<div className="d-none d-lg-block" style={{backgroundColor:"#fff",height:"8px",width:"8px",borderRadius:"4px"}}></div>
					</div>
					<div className="w-75" style={{backgroundColor:"#34353b",height:"2px"}}></div>
				</div>
        <div>
          {/** DATA */}
          {rest.isSingle ? 
            <SingleCell cell={rest} /> :
            <WeekCells week={rest} />
          }
        </div>
      </div>
    )
  }

  const renderLoading = () => (
    <p>Loading mode</p>
  )
  // see fields: src/utils/utils.js -> outRest
  const renderDay = () => {
    const day = restaurant.isSingle ?
      restaurant.singleCell :
      restaurant.weekData[state.day]

    if (!day) return (<></>)

    return (
      <Container>
        <Row>
          <Col>
            <p>Name</p>
          </Col>
          <Col>
            <p>{state.day}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {restaurant.name}
          </Col>
          <Col>
            <div dangerouslySetInnerHTML={{__html: day}} />
          </Col>
        </Row>
      </Container>
    )
  }
  // see fields: src/utils/utils.js -> outRest
  const renderWeek = () => (
    <div className="container-fluid mt-3" style={{maxWidth:"1320px"}}>
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10 p-5" style={{backgroundColor:"#fdfdff",outline:"#f4f8fc solid 2px"}}>
          <RestaurantWeekMenu rest={restaurant} />
        </div>
      </div>
    </div>
  )
  const renderNotFound = () => (
    <div>
      <b>Not found!</b>
    </div>
  )
  const renderMap = {
    [ViewMode.Loading] : renderLoading,
    [ViewMode.Week] : renderWeek,
    [ViewMode.Day] : renderDay,
    [ViewMode.NotFound] : renderNotFound,
  }

  console.log('render mode: ', state.mode)
  const Component = renderMap[state.mode]

  // if (true) return null
  if (!(topMenu && footer)) {
    return null
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={{}}>
      <div className="pb-5">
        <RenderCombo
          name={'Select view mode'}
          options={weekData}
          value={state.day}
          onChange={onChangeCombo}
        />
        <Component />
      </div>
    </Layout>
  )
}
// -------
const RenderCombo = ({name, options, value, onChange}) => (
  <div className="d-flex justify-content-center mt-3">
    <select name={name} value={value} onChange={onChange} className="form-select w-auto" aria-label="Default select example">
      {options.map((item, index) => {
        return (
          <option
            key={index}
            value={item.code}
          >{item.name}</option>)
      })}
    </select>
  </div>
)
//
const loadWeekMenu = async function({client, weekNum}) {
  try {
    const resp = await client.getByUID('menu_day', `week_${weekNum}`);
    // console.log(resp);
    if (!(resp && resp.data && resp.data.body && resp.data.body.length)) return;
    // console.log(JSON.stringify(resp.data.body));
    const mainSlice = resp.data.body.find(item => {
      return item.slice_type === 'main_slice';
    });
    if (!(mainSlice && mainSlice.items && mainSlice.items.length)) return;
    const menu = mainSlice.items;
    console.log(menu);
    return menu;
  } catch(e) {
    console.log(e);
  }
}
const loadSingle = async function({client, customType}) {
  try {
    const resp = await client.getSingle(customType)
    // console.log(`${customType}: `, resp)
    return resp
  } catch(e) {
    console.log(e)
  }
}
const getHash = () => {
  const search = window.location.search
  // console.log(search)
  const _hash = window.location.hash
  const hash = _hash ? _hash.substring(1) : ''
  return hash
}

export default withPrismicPreview(WeekMenu)